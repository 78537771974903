<script>
import {accountMutations, accountStore} from "@/state/modules/accountStoreHelper";
import axios from "axios";
import {msgModalOptCenter} from "@/components/globalFunctions";
import _ from "lodash";
import {loggedInfoObject} from "@/state/helpers";

export default {
  name: "friendsAddCategoryModal",

  data() {
    return {
      addCategoryData: {
        name: "",
        depth: "1",
        level: "1",
        market_srl: ""
      }
    }
  },

  computed: {
    ...accountStore,
    ...loggedInfoObject
  },

  methods: {
    ...accountMutations,

    addTalkCategory(e) {

      console.log("addTalkCategory", this.logged_info)

      this.addCategoryData.market_srl = Number(this.logged_info.mart_srl)

      if (this.addCategoryData.name === "") {
        e.preventDefault()
        this.$bvModal.msgBoxOk("카테고리 제목은 필수입니다.", msgModalOptCenter)
      } else {
        let url = `/api/category/insert`

        axios.post(url, this.addCategoryData)
            .then((res) => {
              console.log('PharmFriendCategoryAddSuccess', res.data)
              this.$emit('childReloadInfo')
            })
            .catch((err) => {
              console.log("addPharmFriendCategory Error :", err)
              if (!_.isEmpty(err.response)) {
                if (err.response.status === 401) {
                  this.setCheckAuth(false)
                }
              }
            })
      }
    },

    initTalkCategoryModal() {
      this.addCategoryData = {
        headerTitle: "",
        description: ""
      }
    }
  }
}
</script>

<template>
  <b-modal
      centered
      id="friendsAddCategoryModal"
      :title="`카테고리 추가`"
      :ok-title="`확인`"
      :cancel-title="`취소`"
      @ok="addTalkCategory"
      @hidden="initTalkCategoryModal"
  >
    <div class="modal-body p-0">
      <div class="row align-items-center mx-0">
        <label class="col-md-3 col-form-label d-flex align-items-center">
          <em style="color: red">*&nbsp;</em>
          제목
        </label>
        <div class="col-md-9">
          <input
              class="form-control"
              type="text"
              placeholder="카테고리 제목"
              v-model="addCategoryData.name"
          />
        </div>
      </div>

      <div v-if="false" class="row align-items-center mx-0">
        <label class="col-md-3 col-form-label d-flex align-items-center ">
          &nbsp;&nbsp;설명
        </label>
        <div class="col-md-9">
          <input
              class="form-control"
              type="text"
              placeholder="설명"
              v-model="addCategoryData.description"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>

</style>
